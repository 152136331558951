/** @jsx jsx */
import { jsx } from '@emotion/core'
import { graphql, Link } from 'gatsby'
import { useState } from 'react'
import { globalHistory } from '@reach/router'
import { Search } from 'js-search'

import ListingLayout from '../layouts/ListingLayout'
import SEO from '../components/seo'

let documents: any[] | null = null
const jsSearch = new Search(['fields', 'slug'])
// jsSearch.addIndex('excerpt')
// jsSearch.addIndex(['frontmatter', 'title'])
jsSearch.addIndex(['frontmatter', 'tag'])
jsSearch.addIndex(['fields', 'slug'])

export const query = graphql`
  query PagedTagListing($limit: Int!, $skip: Int!, $tag: String) {
    allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC},
      filter: { isPast: {eq: true}, frontmatter: { tag: { in: [$tag] } } },
      limit: $limit,
      skip: $skip
    ) {
      nodes {
        excerpt(truncate: true, format: HTML)
        frontmatter {
          title
          tag
          image
        }
        fields {
          slug
        }
        correctedDateEpoch
      }
      totalCount
    }
  }
`

const Tag = ({ data, pageContext }: any) => {
  const pageNumber = pageContext.currentPage
  const postsPerPage = 5

  const loadQ = async (q: string) => {
    if (!documents && typeof fetch !== 'undefined') {
      documents = await fetch(`${globalHistory.location.origin}/search.json`).then((r) => r.json())
      jsSearch.addDocuments(documents as any[])
    }
    const r = ((q ? jsSearch.search(q) : jsSearch.search(pageContext.tag)) || []).sort((p1: any, p2: any) => p2.correctedDateEpoch - p1.correctedDateEpoch)
    setState({
      q,
      nodes: r.slice((pageNumber - 1) * postsPerPage, pageNumber * postsPerPage),
      totalCount: r.length,
    })
  }

  const appendQ = (url: string) => {
    return s.q ? `${url}?q=${s.q}` : url
  }

  const [s, setState] = useState(() => {
    const q = typeof location !== 'undefined' ? new URL(location.href).searchParams.get('q') || '' : ''
    const s = {
      q,
      nodes: q ? [] : data.allMarkdownRemark.nodes,
      totalCount: q ? 0 : data.allMarkdownRemark.totalCount,
    }

    setTimeout(() => {
      loadQ(q)
    }, 10)

    return s
  })

  globalHistory.listen(() => {
    const q = new URL(location.href).searchParams.get('q') || ''
    // console.log(q)

    if (q !== s.q) {
      loadQ(q)
    }
  })

  const getPageCount = () => Math.ceil(s.totalCount / postsPerPage)

  return (
    <ListingLayout
      nodes={ s.nodes }
      pagination={ (
          <ul>
            { pageNumber > 1 ? (
              <li>
                <Link aria-label="Previous" to={ appendQ(pageNumber > 2 ? `${pageContext.prefixUrl || ''}/page/${pageNumber - 1}` : '/') }>&lt;</Link>
              </li>
            ) : null}
            {
              getPageCount() > 1 ? (
                <li className="active">
                  <span>{ pageNumber.toLocaleString() + ' / ' + getPageCount().toLocaleString() }</span>
                </li>
              ) : null
            }
            { pageNumber < getPageCount() ? (
              <li>
                <Link aria-label="Next" to={ appendQ(`${pageContext.prefixUrl || ''}/page/${pageNumber + 1}`) }>&gt;</Link>
              </li>
            ) : null}
          </ul>
      ) }
    >
      <SEO/>
    </ListingLayout>
  )
}

export default Tag
